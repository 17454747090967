import React from 'react';
import PTRUI, { Config } from 'protrans-react-ui';
import LoginForm, { FormTitle } from './scenes/login-form';

export default function AppConfig() {
    const { clearUser, clearToken, isAuth } = PTRUI.useLogin();
    return (

        <Config
            alerts={false}
            search={false}
            mainMenu={false}
            appLogo={'optimiz'}
            appVersion={'2.0.0'}
            apiBaseURLTypes={{
                default: '',
                someApi: ''
            }}
            apiBaseHeaders={{
                'Content-Type': "application/json"
            }}
            appLogin={{
                form: LoginForm,
                formTitle: () => <FormTitle />
            }}
            appLogout={{
                confirmAction: (() => {
                    clearUser();
                })
            }}
            languageSwitcher={false}
        />
    )
}